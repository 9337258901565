import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import AuthService from "@/services/auth-service";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/events'
    },
    {
        path: '/signin',
        name: 'Sign In',
        component: () => import('../views/Signin.vue'),
        meta: {
            requiresAuth: false
        }
    }, {
        path: '/events',
        name: 'Events',
        component: () => import('../views/Events.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/account',
        name: 'Account',
        component: () => import('../views/Account.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/change-password',
        name: 'Change Password',
        component: () => import('../views/ChangePassword.vue'),
        meta: {
            requiresAuth: false
        }
    }, {
        path: '/player',
        name: 'Player',
        component: () => import('../views/Player.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/reset-password',
        name: 'Reset password',
        component: () => import('../views/ResetPassword.vue'),
        meta: {
            requiresAuth: false
        }
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {

    window.scrollTo(0,0);
    if (to.path !== "/") {
        
        if (AuthService.isSignedIn() && to.path == "/signin") {
            return next('/events')
        }

        document.title = "ProcPlayer.tv"
        if (to.meta.requiresAuth) {
            if (!AuthService.isSignedIn()) {
                return next('/signin')
            }
        }
        
    } else {
        document.title = "ProcPlayer.tv"
        if (AuthService.isSignedIn()) {
            return next('/events')
        }
    }


    next()
})

export default router
